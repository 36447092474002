import EurekaHeader, {
    HeaderElements, HeaderSelectors, HeaderClassList, HeaderTexts
} from 'ui-theme/components/header';
import { BaseInjector } from 'ui-theme/js/base';
import { querySelector } from 'ui-theme/utility/utils';
import bootstrapBreakpoints from 'ui-theme/utility/grid';

class Texts extends HeaderTexts { }

class ClassList extends HeaderClassList {
}

class Selectors extends HeaderSelectors {
    loginBarIcon = '.login-bar';
    mainHeader = '.main-header';
    headerAd = '.header-ad-container';
    headerInstitution = '.institution';
    mainMenu = ".main-menu";
    publicationMenu = ".publication-menu";
    sidebarOverlay = ".publication-menu ~ .sidebar__overlay";
    secondaryNavbarToggler = ".secondary-navbar-toggler .navbar-toggler";
    mainSidebarToggle = ".main-navbar .navbar-toggler";
    openPubMenuBtn = ".secondary-navbar-toggler .navbar-toggler:first-child";
    closePubMenuBtn = ".secondary-navbar-toggler .navbar-toggler:last-child";
    closeMainMenuText = ".navbar-toggler__close-text";
    navBarTogglerIcon = ".navbar-toggler__box";
    profileMenuToggler = ".login-bar #profileMenu";
    profileMenu = ".login-bar .dropdown-menu";
    pageTitleToggler = ".secondary-navbar-toggler .page-title-toggler";
    pageMenuTitle = ".publication-menu__nav .page-menu-title";
    headerAdCloseBtn = ".header-ad__close";
    profileMenuRedirectBtn = '.profile-menu__item__redirect-btn';
    profileMenuDialog = '#profileMenuDialog';
    secondaryNavbar = '.secondary-navbar';
    mainLogo = '.navbar-brand > img'
}

class Elements extends HeaderElements {
    loginBarIcon: HTMLElement;
    mainHeader: HTMLElement;
    headerAd: HTMLElement;
    headerInstitution: HTMLElement;
    mainMenu: HTMLElement;
    publicationMenu: HTMLElement;
    sidebarOverlay: HTMLElement;
    secondaryNavbarToggler: HTMLElement;
    mainSidebarToggle: HTMLElement;
    openPubMenuBtn: HTMLElement;
    closePubMenuBtn: HTMLElement;
    selectors: Selectors;
    closeMainMenuText: HTMLElement;
    navBarTogglerIcon: HTMLElement;
    profileMenuToggler: HTMLElement;
    profileMenu: HTMLElement;
    pageTitleToggler: HTMLElement;
    pageMenuTitle: HTMLElement;
    profileMenuDialog: any;
    profileMenuRedirectBtn: HTMLElement;
    headerAdCloseBtn: HTMLElement;
    secondaryNavbar: HTMLElement;
    mainLogo: HTMLElement;

    initElements() {
        super.initElements();
        this.mainSidebarToggle = querySelector(this.selectors.mainSidebarToggle);
        this.loginBarIcon = querySelector(this.selectors.loginBarIcon);
        this.mainHeader = querySelector(this.selectors.mainHeader);
        this.headerAd = querySelector(this.selectors.headerAd);
        this.headerInstitution = querySelector(this.selectors.headerInstitution);
        this.mainMenu = querySelector(this.selectors.mainMenu);
        this.publicationMenu = querySelector(this.selectors.publicationMenu);
        this.sidebarOverlay = querySelector(this.selectors.sidebarOverlay);
        this.secondaryNavbarToggler = querySelector(this.selectors.secondaryNavbarToggler);
        this.openPubMenuBtn = querySelector(this.selectors.openPubMenuBtn);
        this.closePubMenuBtn = querySelector(this.selectors.closePubMenuBtn);
        this.closeMainMenuText = querySelector(this.selectors.closeMainMenuText);
        this.navBarTogglerIcon = querySelector(this.selectors.navBarTogglerIcon);
        this.profileMenuToggler = querySelector(this.selectors.profileMenuToggler);
        this.profileMenu = querySelector(this.selectors.profileMenu);
        this.pageTitleToggler = querySelector(this.selectors.pageTitleToggler);
        this.pageMenuTitle = querySelector(this.selectors.pageMenuTitle);
        this.headerAdCloseBtn = querySelector(this.selectors.headerAdCloseBtn);
        this.profileMenuRedirectBtn = querySelector(this.selectors.profileMenuRedirectBtn);
        this.profileMenuDialog = querySelector(this.selectors.profileMenuDialog);
        this.secondaryNavbar = querySelector(this.selectors.secondaryNavbar);
        this.mainLogo = querySelector(this.selectors.mainLogo);
    }
}

interface Header {
    get elements(): Elements;
    get texts(): Texts;
    get classList(): ClassList;
    get selectors(): Selectors;
}


@BaseInjector(Selectors, Elements, ClassList, Texts)
class Header extends EurekaHeader {
    headerAdHeight: number;
    headerInstitutionHeight: number;
    mainHeaderHeight: number;
    secondaryNavbarHeight: number;
    scrolledDistance: number = window.scrollY || window.pageYOffset;

    initialize(): void {
        super.initialize();

        // set the height of elements
        this.headerAdHeight = this.elements.headerAd && this.elements.headerAd.offsetHeight || 0;
        this.headerInstitutionHeight = this.elements.headerInstitution && this.elements.headerInstitution.offsetHeight || 0;
        this.mainHeaderHeight = this.elements.mainHeader && this.elements.mainHeader.offsetHeight || 0;
        if (this.elements.secondaryNavbar) {
            this.secondaryNavbarHeight = this.elements.secondaryNavbar && this.elements.secondaryNavbar.offsetHeight;
        }

        // set the totalHeaderHeight when instance initialized
        const root = querySelector(':root');
        const totalHeaderHeight = this.headerAdHeight + (this.headerInstitutionHeight || 40) + this.mainHeaderHeight;
        setTimeout(() => {
            root.style.setProperty('--totalHeaderHeight', `${totalHeaderHeight}px`);
        }, 0);
        
        // re-calculate after the ad inserted into the DOM
        if(this.elements.headerAd) {
            // Create a new MutationObserver
            const observer = new MutationObserver(mutationsList => {
                for (let mutation of mutationsList) {
                    if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                        // Handle the DOM mutation here
                        this.headerAdHeight = this.elements.headerAd.offsetHeight || 0;
                        root.style.setProperty('--totalHeaderHeight', `${totalHeaderHeight}px`);
                        // if we are at the Article Page
                        if (this.wrapper.classList.contains('article-header')) {
                            this.elements.mainHeader.style.top = "0px";
                        } else {
                            this.elements.mainHeader.style.top = `${this.headerAdHeight}px`;
                        }
                        if (this.elements.publicationMenu) {
                            this.setTopOfPublicationMenu(this.setScrollDistance());
                        }
                    }
                }
            });

            observer.observe(this.elements.headerAd, { childList: true, subtree: true });
        }

        // set the top of header based on view port
        if (window.innerWidth < bootstrapBreakpoints.md) {
            this.elements.mainHeader.style.top = "0px";
        } else {
            this.elements.mainHeader.style.top = `${this.headerAdHeight}px`;
        }

        // cancel the sticky behaviour of the Ad after 10 sec
        setTimeout(() => {
            this.removeAdStickyBehaviour();
        }, 10000);

        // Js code for profile personal information dialog
        if (this.elements.profileMenuDialog && this.elements.profileMenuRedirectBtn) {
            this.elements.profileMenuRedirectBtn.addEventListener('click', () => {
                this.elements.profileMenuDialog.showModal();
            })
        }

        // Js code for cancel Ad sticky behaviour
        if (this.elements.headerAdCloseBtn) {
            this.elements.headerAdCloseBtn.addEventListener('click', () => {
                this.removeAdStickyBehaviour();
            })
        }

        // Main Sidebar Toggle
        if (this.elements.mainSidebarToggle) {
            this.elements.mainSidebarToggle.addEventListener('click', () => {
                this.hideShowLoginBarIcon();
                this.hideShowCloseMainMenuToggler();
            })
        }

        // add functionality on profile menu
        this.hideShowProfileMenuOnHover();

        // handle the top of publication menu
        if (this.elements.publicationMenu) {
            // to change the publication menu title based on the page
            if (this.elements.pageMenuTitle && this.elements.pageTitleToggler) {
                this.changePublicationMenuPageTitles();
            }

            this.elements.openPubMenuBtn.addEventListener('click', () => {
                this.hideShowPublicationMenuToggler();
            })

            this.elements.closePubMenuBtn.addEventListener('click', () => {
                this.hideShowPublicationMenuToggler();
            })

            this.elements.sidebarOverlay.addEventListener('click', () => {
                this.hideShowPublicationMenuToggler();
            })

            this.elements.mainSidebarToggle?.addEventListener('click', () => {
                this.hideShowPubMenu(this.elements.secondaryNavbarToggler)
            })

            this.elements.secondaryNavbarToggler?.addEventListener('click', () => {
                this.hideShowMainMenu(this.elements.mainSidebarToggle);
            })

            // tp set the top of the publication menu
            this.setTopOfPublicationMenu(this.setScrollDistance());

            if (window.innerWidth < bootstrapBreakpoints.xl) {
                window.addEventListener('scroll', () => {
                    this.scrolledDistance = window.scrollY || window.pageYOffset;
                    // For article page
                    if (this.wrapper.classList.contains('article-header')) {
                        this.setTopOfPublicationMenu(this.scrolledDistance);
                    } else {
                        this.setTopOfPublicationMenu(this.setScrollDistance());
                    }
                });
            }
        }

        // Change the logo size based on scrolling
        if (window.innerWidth > bootstrapBreakpoints.lg) {
            window.addEventListener('scroll', () => {
                this.scrolledDistance = window.scrollY || window.pageYOffset;
            });
        }

        // Remove the sticky behaviour when we are on article page
        if (this.wrapper.classList.contains('article-header')) {
            if (this.elements.headerAd) {
                this.elements.headerAd.style.position = "relative";
            }
            this.elements.mainHeader.style.top = "0px";
        }
    }

    removeAdStickyBehaviour(): void {
        const root = querySelector(':root');
        // set the total header height when remove the Ad sticky behaviour
        root.style.setProperty('--totalHeaderHeight', `${(this.headerInstitutionHeight || 40) + this.mainHeaderHeight}px`);
        if (this.elements.headerAd) {
            this.elements.headerAd.style.position = "relative";
        }

        this.elements.mainHeader.style.top = "0px";
        if (this.elements.headerAdCloseBtn) {
            this.elements.headerAdCloseBtn.classList.add('d-none')
        }

        // if there is a publication menu, set the top of it
        if (this.elements.publicationMenu) {
            this.setTopOfPublicationMenu(this.setScrollDistance());
        }
    }

    hideShowPublicationMenuToggler(): void {
        if (this.elements.openPubMenuBtn.classList.contains('hidden') && !this.elements.closePubMenuBtn.classList.contains('hidden')) {
            this.elements.openPubMenuBtn.classList.remove('hidden');
            this.elements.closePubMenuBtn.classList.add('hidden');
        } else if (!this.elements.openPubMenuBtn.classList.contains('hidden') && this.elements.closePubMenuBtn.classList.contains('hidden')) {
            this.elements.openPubMenuBtn.classList.add('hidden');
            this.elements.closePubMenuBtn.classList.remove('hidden');
        }
    }

    hideShowCloseMainMenuToggler(): void {
        if (this.elements.closeMainMenuText.classList.contains('hidden') && !this.elements.navBarTogglerIcon.classList.contains('open')) {
            this.elements.closeMainMenuText.classList.remove('hidden');
            this.elements.navBarTogglerIcon.classList.add('open');
        } else {
            this.elements.closeMainMenuText.classList.add('hidden');
            this.elements.navBarTogglerIcon.classList.remove('open');
        }
    }

    hideShowLoginBarIcon(): void {
        if (this.elements.loginBarIcon.classList.contains('hidden')) {
            this.elements.loginBarIcon.classList.remove('hidden')
        } else {
            this.elements.loginBarIcon.classList.add('hidden')
        }
    }

    hideShowMainMenu(navToggler: HTMLElement): void {
        if (this.elements.mainMenu.classList.contains('show')) {
            navToggler.click();
        }
    }

    hideShowPubMenu(navToggler: HTMLElement) {
        if (this.elements.publicationMenu.classList.contains('show')) {
            navToggler.click();
        }
    }

    setScrollDistance(): number {
        if (this.scrolledDistance >= this.headerInstitutionHeight && window.innerWidth < bootstrapBreakpoints.md) {
            return this.headerInstitutionHeight;
        } else if (this.scrolledDistance >= (this.headerAdHeight + this.headerInstitutionHeight) && window.innerWidth >= bootstrapBreakpoints.md) {
            if (this.elements.headerAd.style.position === "relative") {
                return this.headerAdHeight + this.headerInstitutionHeight;
            } else {
                return this.headerInstitutionHeight;
            }
        } else {
            return this.scrolledDistance;
        }
    }

    setTopOfPublicationMenu(scrollDistance: number = 0): void {
        let top = 0;

        if (this.elements.headerAd) {
            top = top + this.headerAdHeight;
        }

        if (this.elements.headerInstitution) {
            top = top + this.headerInstitutionHeight;
        }

        if (this.elements.mainHeader) {
            top = top + this.mainHeaderHeight;
        }

        if (this.elements.publicationMenu && this.elements.sidebarOverlay) {
            this.elements.publicationMenu.style.top = `${top - scrollDistance}px`;
            this.elements.sidebarOverlay.style.top = `${top - scrollDistance}px`;
            this.elements.publicationMenu.style.maxHeight = `calc(100% - ${top - scrollDistance}px)`;
        }
    }

    hideShowProfileMenuOnHover(): void {
        if (this.elements.profileMenuToggler && this.elements.profileMenu) {
            this.elements.profileMenuToggler.addEventListener('mouseover', () => {
                this.elements.profileMenu.classList.add('show');
                this.elements.profileMenuToggler.setAttribute("aria-expanded", "true");
            });

            this.elements.profileMenuToggler.addEventListener('mouseout', () => {
                this.elements.profileMenu.classList.remove('show');
                this.elements.profileMenuToggler.setAttribute("aria-expanded", "false");
            });

            this.elements.profileMenu.addEventListener('mouseover', () => {
                this.elements.profileMenu.classList.add('show');
                this.elements.profileMenuToggler.setAttribute("aria-expanded", "true");
            });

            this.elements.profileMenu.addEventListener('mouseout', () => {
                this.elements.profileMenu.classList.remove('show');
                this.elements.profileMenuToggler.setAttribute("aria-expanded", "false");
            });
        }
    }

    changePublicationMenuPageTitles(): void {
        const currentURL = window.location.href;
        const urlParts = currentURL.split('/');
        let serialCode = "";
        const mainWrapper = querySelector("div[data-pb-dropzone='main'] > div");
        if(mainWrapper) {
            serialCode = mainWrapper.classList.value;
        }

        if (urlParts.length >= 3) {
            const currentPage = urlParts[3];
            if (currentURL.includes("/pn") || currentURL.includes("pn") || currentURL.includes("/topic")) {
                this.elements.pageMenuTitle.innerText = "Psychiatric News";
                this.elements.pageTitleToggler.innerText = "Psychiatric News";
            } else if (currentPage == "dsm" || serialCode.includes("dsm")) {
                this.elements.pageMenuTitle.innerText = "DSM Library";
                this.elements.pageTitleToggler.innerText = "DSM Library";
            } else {
                this.elements.pageMenuTitle.innerText = "Journal Menu";
                this.elements.pageTitleToggler.innerText = "Journal Menu";
            }
        }
    }
}

export default Header;

export {
    Elements as HeaderElements,
    Selectors as HeaderSelectors,
    ClassList as HeaderClassList,
    Texts as HeaderTexts
}

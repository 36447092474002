import { BaseElements, BaseObject, BaseService, BaseInjector } from 'ui-theme/js/base';
import { EmptyNodeList } from 'ui-theme/utility/utils/index';

class Texts extends BaseObject { }

class ClassList extends BaseObject { }

class Selectors extends BaseObject {
    navLinks: string = ".nav-link";
}

class Elements extends BaseElements<Selectors> {
    navLinks: NodeListOf<HTMLElement> = new EmptyNodeList();

    constructor(selectors: Selectors) {
        super(selectors, false);
    }

    setWrapper(wrapper: HTMLElement) {
        this.wrapper = wrapper;
        this.initElements()
    }

    // Loop over all elements and init them
    initElements() {
        this.initElementsByLoop(this.wrapper);
    }
}

interface AccessEntitlements extends BaseService<Selectors, Elements, ClassList, Texts> { }

@BaseInjector(Selectors, Elements, ClassList, Texts)
class AccessEntitlements {

    constructor(protected readonly wrapper: any) { }

    initialize(): void {
        this.elements.setWrapper(this.wrapper);
        this.addEventListeners();
    }

    addEventListeners(): void {
        this.navigateOnClick()
    }

    navigateOnClick(): void {
        const { pathname, search } = window.location;
        const params = search.split("&");

        if(this.elements.navLinks && this.elements.navLinks.length > 0 ){
            this.elements.navLinks.forEach((link: HTMLAnchorElement)=> {
                const tabGroup = (link.getAttribute('href') || "").split("-");
                if(!search.includes(tabGroup[1])){
                    link.addEventListener("click", ()=> {
                        const url = `${pathname}${params[0]}&page=1&selectedTab=${tabGroup[1]}`
                        window.location.href =  url
                    })
                }
            })
        }
    }
}

export default AccessEntitlements;

export {
    Elements as AccessEntitlementsElements,
    Selectors as AccessEntitlementsSelectors,
    ClassList as AccessEntitlementsClassList,
    Texts as AccessEntitlementsTexts
}
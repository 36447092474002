import { BaseInjector } from 'ui-theme/js/base';
import EurekaQuickSearch, {
    QuickSearchElements,
    QuickSearchSelectors,
    QuickSearchClassList,
    QuickSearchTexts
} from 'ui-theme/widgets/quick-search/index';

class Texts extends QuickSearchTexts { }

class ClassList extends QuickSearchClassList { }

class Selectors extends QuickSearchSelectors {
}

class Elements extends QuickSearchElements {

    initElements() {
        super.initElements();
    }
}

interface QuickSearch {
    get elements(): Elements;
    get texts(): Texts;
    get classList(): ClassList;
    get selectors(): Selectors;
}

@BaseInjector(Selectors, Elements, ClassList, Texts)
class QuickSearch extends EurekaQuickSearch {

    get submitWithoutChoosingValue(): boolean {
        return true;
    }

    constructor(readonly wrapper: HTMLElement) {
        super();
    }

    initialize(): void {
        super.initialize();
    }
}

export default QuickSearch;